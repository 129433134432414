export default [
    { itemName: 'Butterfly Toy', itemType: "Accessories", contract: 'ItemButterflyToy', tokenAddress: '0x10b9460dc6d39b880825391af047405a50db908f', artistName: 'BxKat', assetUrl: 'butterflytoy' },
    { itemName: 'Eyepatch', itemType: "Accessories", contract: 'ItemEyepatch', tokenAddress: '0x0400180d3211eb58d693dae22b98585ea77db3ca', artistName: 'BxKat', assetUrl: 'eyepatch' },
    { itemName: 'Surrounded by Love 2', itemType: "Accessories", contract: 'ItemHearts', tokenAddress: '0xfebc83bc7f97690f94bc2888d399b740492733b5', artistName: 'BxKat', assetUrl: 'heartsbg2' },
    { itemName: 'Pearl Necklace', itemType: "Accessories", contract: 'ItemPearlNecklace', tokenAddress: '0x8227606e616e50d899eca277e6ceef7719bb9922', artistName: 'BxKat', assetUrl: 'pearlnecklace' },
    { itemName: 'Spiked Cuffs', itemType: "Accessories", contract: 'ItemSpikedCuffs', tokenAddress: '0xba14052f4f351f0f992e7418e45cb02784e7de01', artistName: 'BxKat', assetUrl: 'spikedcuffs'},
    { itemName: 'Surrounded by Stars', itemType: "Accessories", contract: 'ItemStars', tokenAddress: '0x7ae147d2473fe36f548173b6b04e608fa1943c7f', artistName: 'BxKat', assetUrl: 'starsbg'},
    { itemName: 'Fuzzy Ball of Yarn', itemType: "Accessories", contract: 'ItemYarn', tokenAddress: '0xb9e9ae95ffd4223f14b87e565ad0f00838a566f2', artistName: 'BxKat', assetUrl: 'yarnball'},
    { itemName: 'Diamond Bracelet', itemType: "Accessories", contract: 'ItemSpikedBracelet', tokenAddress: '0x8518f0eb44247c62d07a5ff0d97137a6285554c8', artistName: 'Janbro', assetUrl: 'diamond_bracelet'},
    { itemName: 'Ethereum Collar', itemType: "Accessories", contract: 'ItemEthereumCollar', tokenAddress: '0x280a511ebad860e945e1829e2c8435748b05d147', charity: 'ASPCA and ETH Foundation', assetUrl: 'collar'},
    { itemName: 'iWatch', itemType: "Accessories", contract: 'ItemiWatch', tokenAddress: '0x54616a975dec9256f8fe26a99b2fcd6affbf6eaf', assetUrl: 'iwatch'},
    { itemName: 'Lei', itemType: "Accessories", contract: 'ItemLei', tokenAddress: '0x5e064285ec172449a85643c2000a4f1b390e16c2', assetUrl: 'lei'},
    { itemName: 'Mom Tattoo', itemType: "Accessories", contract: 'ItemMomTattoo', tokenAddress: '0x96e617630c15c3a4b4fde32f6eec434ae31b392f', assetUrl: 'momtat'},
    { itemName: 'Bowtie', itemType: "Accessories", contract: 'ItemBowtie', tokenAddress: '0xf6eb0570fb3f4ccfb21aea5603b3163d22070cf2', artistName: 'KittyHawk', assetUrl: 'bowtie'},
    { itemName: 'Ladybug Headband', itemType: "Accessories", contract: 'ItemLadybug', tokenAddress: '0x04bc7647a545cc8eee31280b3e3eb3b2e8b2d978', artistName: 'KittyHawk', assetUrl: 'ladybug'},
    { itemName: 'Viking Shield', itemType: "Accessories", contract: 'ItemVikingShield', tokenAddress: '0x7b412a4dafbdea838e646af207999d5e05d9f077', artistName: 'KittyHawk', assetUrl: 'vikingshield'},
    { itemName: 'Monocle', itemType: "Accessories", contract: 'ItemMonocle', tokenAddress: '0xc08a7840a35bc9f11801479c81532b2ac72a7c82', artistName: 'KittyHawk', assetUrl: 'monocle'},
    { itemName: 'Paw Me Pls', itemType: "Accessories", contract: 'ItemPawMePls', tokenAddress: '0xba79987d39d004802a157c27436aadcc16469503', artistName: 'KittyHawk', assetUrl: 'sign_pawmepls'},
    { itemName: 'Chocolate Pudding Headband', itemType: "Accessories", contract: 'ItemChocoHeadband', tokenAddress: '0xf5353cb6ef1eeeeada2ff197d991492904fcfcad', artistName: 'KittyHawk', assetUrl: 'headband_choco'},
    { itemName: '3D glasses', itemType: "Accessories", contract: 'Item3dGlasses', tokenAddress: '0xddef3b261fa9a5995691838ce97eac616b34fcad', artistName: 'KittyHawk', assetUrl: 'glasses_3d'},
    { itemName: 'Time Machine', itemType: "Accessories", contract: 'ItemTimeMachine', tokenAddress: '0x1f5a521d8fa643bf699b477b0c9edefd82864cab', artistName: 'KittyHawk', assetUrl: 'box_timemachine'},
    { itemName: 'Red Flower', itemType: "Accessories", contract: 'ItemFlowerRed', tokenAddress: '0xe66ce7ea9738165a42fb898fe081ed10e50396c5', artistName: 'KittyHawk', assetUrl: 'flower_red'},
    { itemName: 'Bubbles For Sadie (#K4C)', itemType: "Accessories", contract: 'ItemBubblesForSadie', tokenAddress: '0xb45287d3b10a60025d1973c995e015708e2fb23f', artistName: 'KittyHawk', charity: 'Kitties For a Cause', assetUrl:"bubbles4sadie" },
    { itemName: 'Bux-To-Go', itemType: "Accessories", contract: 'ItemBuxToGo', tokenAddress: '0x79d8c7a6320ceb5e3269e78f37573972ab27110d', artistName: 'MH10K', assetUrl: 'Buxtogo'},
    { itemName: 'Kitty Coffee Co.', itemType: "Accessories", contract: 'ItemKittyCoffee', tokenAddress: '0x0efd0a6b16dc09536133df3f208ee4414b03483e', artistName: 'MH10K', assetUrl: 'Ckcoffeeco'},
    { itemName: 'Morning Cup-a-Joe', itemType: "Accessories", contract: 'ItemCoffeeMug', tokenAddress: '0xdb4ebb8c117350e2294b8a2be295684dbd66c001', artistName: 'MH10K', assetUrl: 'Coffeemug'},
    { itemName: 'Coffee Cup and Saucer', itemType: "Accessories", contract: 'ItemCoffeeSaucer', tokenAddress: '0x3444c87489aebceb43ce8ac9dfa12a15d3c9becf', artistName: 'MH10K', assetUrl: 'Coffeesaucer'},
    { itemName: 'Martini', itemType: "Accessories", contract: 'ItemMartini', tokenAddress: '0x71c2bc4df088be91e315207a190cc7b3257f6742', artistName: 'MH10K', assetUrl: 'Bluemartini'},
    { itemName: 'Cosmopolitan', itemType: "Accessories", contract: 'ItemCosmopolitan', tokenAddress: '0x8442f99a0eac542cd6a30c1c557f146fda628c1e', artistName: 'MH10K', assetUrl: 'Pinkmartini'}, 
    { itemName: 'Tea Time', itemType: "Accessories", contract: 'ItemTeaCup', tokenAddress: '0xb3b52fad9fa87eb09de3ec2a2bb3988f875f2ad6', artistName: 'MH10K', assetUrl: 'Teacup'},
    { itemName: 'Dead Bird', itemType: "Accessories", contract: 'ItemDeadBird', tokenAddress: '0x4ef99102c1bcd63dc441a1887c65b419ee0effac', artistName: 'ParanoidLunatic', assetUrl: 'birdy'}, 
    { itemName: 'KH Tattoo', itemType: "Accessories", contract: 'ItemKHTattoo', tokenAddress: '0x594fc7779454ddcf05b9b04043b3dacc0b9521a2', artistName: 'ParanoidLunatic', assetUrl: 'khtattoo'},
    { itemName: 'White Flower', itemType: "Accessories", contract: 'ItemFlowerWhite', tokenAddress: '0xc6202f8542e480a2a7915a2992bd1b996780e6aa', artistName: 'ParanoidLunatic', assetUrl: 'whiteflower'}, 
    { itemName: 'Grey Silk Scarf', itemType: "Accessories", contract: 'ItemGreySilkScarf', tokenAddress: '0x54ad662dbf6627582dfddc6a3d2ff60770e3c371', artistName: 'ParanoidLunatic', assetUrl: 'greysilkscarf'},
    { itemName: 'Doge Onesie', itemType: "Costumes", contract: 'ItemDogeConToken', tokenAddress: '0xac4eb9178a2e5b3a1e513eb558f02eea4ea68345', artistName: 'ADHDKitties', assetUrl: 'doge'},
    { itemName: 'Bellhop', itemType: "Costumes", contract: 'ItemBellhop', tokenAddress: '0x21fcc28ff374bb2ff28672c7047304e0950e22a6', artistName: 'KittyHawk', assetUrl: 'bellhop'},
    { itemName: 'Kittyhawk Costume', itemType: "Costumes", contract: 'ItemKittyHawk', tokenAddress: '0x7f08f6489c177e6b7b754d65ec14d26cdeb5aeac', artistName: 'KittyHawk', assetUrl: 'kittyhawk_costume'},
    { itemName: 'Ballerina Outfit', itemType: "Costumes", contract: 'ItemBalletCostume', tokenAddress: '0x2a11b2990dca671df8f9e71754b94eb49b62a516', artistName: 'KittyHawk', assetUrl: 'ballerina_costume'},
    { itemName: 'Blue Suit', itemType: "Costumes", contract: 'ItemBlueSuit', tokenAddress: '0x7af0f7286fc4a1719c08bebc5a5a0961b1a24e03', artistName: 'MH10K', assetUrl: 'Bluesuit'},
    { itemName: 'The Clooney', itemType: "Costumes", contract: 'ItemClooney', tokenAddress: '0xe7799c4f097a40651dad1b17159fcf5805d9a9c6', artistName: 'MH10K', assetUrl: 'Clooney'},
    { itemName: 'Grey Suit', itemType: "Costumes", contract: 'ItemGreySuit', tokenAddress: '0x59dddaf0c142739d963ca3741d04330d48988f68', artistName: 'MH10K', assetUrl: 'Greysuit'},
    { itemName: 'Miami Suit', itemType: "Costumes", contract: 'ItemMiamiSuit', tokenAddress: '0xf4955dd4de4864f0fa7fbded77588e6308a0f569', artistName: 'MH10K', assetUrl: 'Miamisuit'},
    { itemName: 'Tuxedo', itemType: "Costumes", contract: 'ItemTuxedo', tokenAddress: '0x13b9c431e6e11ff9665c578dab58f20950b7877b', artistName: 'MH10K', assetUrl: 'Tuxedo'},
    { itemName: 'White Tuxedo', itemType: "Costumes", contract: 'ItemWhiteTuxedo', tokenAddress: '0x4db80cd3b953689955e4b9197c1d5d6c369755f8', artistName: 'MH10K', assetUrl: 'Whitetux'},
    { itemName: 'Batman Suit', itemType: "Costumes", contract: 'ItemBatman', tokenAddress: '0x18b632cafd38cc354cc3963d503d098cb73c1720', artistName: 'ParanoidLunatic', assetUrl: 'batman'},
    { itemName: 'Superman Suit', itemType: "Costumes", contract: 'ItemSuperman', tokenAddress: '0x91739239eb12dcce94d0d6be43f55e2993a5588e', artistName: 'ParanoidLunatic', assetUrl: 'superman'},
    { itemName: 'The Scream', itemType: 'dada.nyc', contract: 'ItemDadaTheScream', tokenAddress: '0xc970e68462089faac863d01fec18f7c1a4878a27', artistName: 'BeatrizRamos@Dada', assetUrl: 'dada-thescream', url: "https://dada.nyc/pa/78673?khf=on#78673"},
    { itemName: 'Self Portrait, Dedicated to Dr. Eloesser', itemType: 'dada.nyc', contract: 'ItemDadaFrida', tokenAddress: '0xe3244b78ba9d11c6ce0ea2d1a5e94c2a18930e8f', artistName: 'BeatrizRamos@Dada', assetUrl: 'dada-frida', url: "https://dada.nyc/pa/79079?khf=on#79079"},
    { itemName: 'American Gothic', itemType: 'dada.nyc', contract: 'ItemDadaAmericanGothic', tokenAddress: '0xb8e8faf64d0328c3607e66b5d46660fdb935dae3', artistName: 'Betunski@Dada', assetUrl: 'dada-americangothic', url: "https://dada.nyc/pa/79090?khf=on#79090"},
    { itemName: 'The Creation of Adam', itemType: 'dada.nyc', contract: 'ItemDadaCreationOfAdam', tokenAddress: '0x24d677155983fefedba09e754dabba356cbb25d7', artistName: 'BorisSZimunich@Dada', assetUrl: 'dada-creationadam',url: "https://dada.nyc/pa/78095?khf=on#78095"},
    { itemName: 'The Temptation of St. Anthony', itemType: 'dada.nyc', contract: 'ItemDadaTemptation', tokenAddress: '0x022cf1ad0e4256a64dce8c095962e98cf09fa588', artistName: 'BorisToledo@Dada', assetUrl: 'dada-temptation',url: "https://dada.nyc/pa/78484?khf=on#78484"},
    { itemName: 'Lady with an Ermine', itemType: 'dada.nyc', contract: 'ItemDadaLadyWithErmine', tokenAddress: '0x9f0cdf83bf574cc7fe51ad628e320f2f51330427', artistName: 'BorisToledo@Dada', assetUrl: 'dada-ladywithermine',url: "https://dada.nyc/pa/82624?khf=on#82624"},
    { itemName: 'Mona Lisa', itemType: 'dada.nyc', contract: 'ItemDadaMonaLisa', tokenAddress: '0x28c1fd2567f12b5d7a97551619aa85a2a344b598', artistName: 'BorisToledo@Dada', assetUrl: 'dada-monalisa',url: "https://dada.nyc/pa/78094?khf=on#78094"},
    { itemName: 'Bedroom in Arles', itemType: 'dada.nyc', contract: 'ItemDadaBedroomArles', tokenAddress: '0x4e775cef6adf0e2ad1f4cbb07218f70bfa2bd8e9', artistName: 'BorisToledo@Dada', assetUrl: 'dada-bedroomarles', url: "https://dada.nyc/pa/78716?khf=on#78716"},
    { itemName: 'Girl with a Pearl Earring', itemType: 'dada.nyc', contract: 'ItemDadaPearlEarring', tokenAddress: '0x446a228541433c42c1df2381a1b5c0b08f1f61d3', artistName: 'BorisToledo@Dada', assetUrl: 'dada-pearlearring',url: "https://dada.nyc/pa/79076?khf=on#79076"},
    { itemName: 'The Birth of Venus', itemType: 'dada.nyc', contract: 'ItemDadaBirthVenus', tokenAddress: '0xe61185af22c67c30484a315eb2a06879c7dfb376', artistName: 'Cromomaniaco@Dada', assetUrl: 'dada-birthvenus',url: "https://dada.nyc/pa/78265?khf=on#78265"},
    { itemName: 'The Raft of the Medusa', itemType: 'dada.nyc', contract: 'ItemDadaRaftMedusa', tokenAddress: '0x5eb190083b940cb297531ab95ebf4707cbfbb3af', artistName: 'Cromomaniaco@Dada', assetUrl: 'dada-raftmedusa',url: "https://dada.nyc/pa/78429?khf=on#78429"},
    { itemName: 'Las Meninas', itemType: 'dada.nyc', contract: 'ItemDadaLasMeninas', tokenAddress: '0xd4b1173b33664cbfcc61262286ca50de6bf140f2', artistName: 'Cromomaniaco@Dada', assetUrl: 'dada-lasmeninas',url: "https://dada.nyc/pa/78945?khf=on#78945"},
    { itemName: 'The Anatomy Lesson of Dr. Nicolaes Tulp', itemType: 'dada.nyc', contract: 'ItemDadaAnatomyLesson', tokenAddress: '0x194603a59bd660f8f98e412bfbb5ebf8acf0a18d', artistName: 'JavierErrecarte@Dada', assetUrl: 'dada-anatomylesson',url: "https://dada.nyc/pa/79135?khf=on#79135"},
    { itemName: 'Woman in Front of the Sun', itemType: 'dada.nyc', contract: 'ItemDadaWomanSun', tokenAddress: '0x195518075a8596e1c2f5a645b2391d08c030cf63', artistName: 'LorenaPinasco@Dada', assetUrl: 'dada-womansun',url: "https://dada.nyc/pa/78729?khf=on#78729"},
    { itemName: 'Portrait of Adele Bloch-Bauer I', itemType: 'dada.nyc', contract: 'ItemDadaPortraitAdele', tokenAddress: '0x39fc7602c6de77b0e9dfe2217b2174c1fe617161', artistName: 'MaFerGarzon@Dada', assetUrl: 'dada-portraitadele',url:"https://dada.nyc/pa/79477?khf=on#79477"},
    { itemName: 'Seated Dancer', itemType: 'dada.nyc', contract: 'ItemDadaSeatedDancer', tokenAddress: '0xbf3512424972f1e602515c775531b06a734eaf7a', artistName: 'Massel@Dada', assetUrl: 'dada-seateddancer',url: "https://dada.nyc/pa/79083?khf=on#79083"},
    { itemName: 'The Dance Class', itemType: 'dada.nyc', contract: 'ItemDadaDanceClass', tokenAddress: '0x262d945acc14a0c38971c16812b6d292c3c10657', artistName: 'Massel@Dada', assetUrl: 'dada-danceclass',url: "https://dada.nyc/pa/78708?khf=on#78708"},
    { itemName: 'Two Women at a Window', itemType: 'dada.nyc', contract: 'ItemDadaTwoWomen', tokenAddress: '0xe489a18c1dbc902881e0fcc71fc10e1c4b4264dd', artistName: 'Massel@Dada', assetUrl: 'dada-twowomen',url: "https://dada.nyc/pa/82944?khf=on#82944"},
    { itemName: 'At the Moulin Rouge', itemType: 'dada.nyc', contract: 'ItemDadaMoulinRouge', tokenAddress: '0xcaf91cac15d94454652813a8e0ffcc69dc6ec66c', artistName: 'RaulAvila@Dada', assetUrl: 'dada-moulinrogue',url:"https://dada.nyc/pa/78674?khf=on#78674"},
    { itemName: 'The Starry Night', itemType: 'dada.nyc', contract: 'ItemDadaStarryNight', tokenAddress: '0x6d71052ef96c45f4455bb96f2c511f03804c62f2', artistName: 'VanesaStati@Dada', assetUrl: 'dada-starrynight',url: "https://dada.nyc/pa/78678?khf=on#78678"},
    { itemName: "Whistler's Mother", itemType: 'dada.nyc', contract: 'ItemDadaWhistlersMother', tokenAddress: '0xdb88804df82a9598070ea7fdf2d6cb8c59cc05d8', artistName: 'VanesaStati@Dada', assetUrl: 'dada-whistlersmother',url: "https://dada.nyc/pa/78869?khf=on#78869"},
    { itemName: 'Jupiter and Io', itemType: 'dada.nyc', contract: 'ItemDadaJupiterIo', tokenAddress: '0xba54d1d901328d5ba471e1c451bd87461e3b8e59', artistName: 'SerenaStelitano@Dada', assetUrl: 'dada-jupiterlo',url: "https://dada.nyc/pa/79512?khf=on#79512" },
    { itemName: 'Nerd', itemType: 'Glasses', contract: 'ItemNerdGlasses', tokenAddress: '0x837e4831753e32437bac397cefc8da67f61a74e6', assetUrl: 'nerd'},
    { itemName: 'Shutter Shades', itemType: 'Glasses', contract: 'ItemShutterShades', tokenAddress: '0xda51c77380e3653189b65f3f3ffeec63d88f3ae8', assetUrl: 'shutter'},
    { itemName: 'Wayfarers', itemType: 'Glasses', contract: 'ItemWayfarers', tokenAddress: '0xb1df962bb6938deb7b27cdc01ea0b41395c5d426', assetUrl: 'wayfarers'},
    { itemName: 'Gold Halo', itemType: 'Hats', contract: 'ItemGoldHalo', tokenAddress: '0x58adcda20d735667276d534f67afc2a1522dd8fc', artistName: 'BxKat', assetUrl: 'goldhalo'},
    { itemName: 'Silver Halo', itemType: 'Hats', contract: 'ItemSilverHalo', tokenAddress: '0xb42cf1e8ed1c590d1621410e659e2a56caf8d4d7', artistName: 'BxKat', assetUrl: 'silverhalo'},
    { itemName: 'Pirate Hat', itemType: 'Hats', contract: 'ItemPirateHat', tokenAddress: '0xe3e991f299afdb1405b1dcf066f70032544aeab8', artistName: 'BxKat', assetUrl: 'piratehat'},
    { itemName: 'Flat Brim', itemType: 'Hats', contract: 'ItemFlatBrim', tokenAddress: '0x64e5e8fe8210bca8ad0571b4711d77161ac459a3', assetUrl: 'flatbrim'},
    { itemName: 'Tiara', itemType: 'Hats', contract: 'ItemTiara', tokenAddress: '0x2a7fa1308c075cf214e66c2caa67883da4fa508f', assetUrl: 'tiara'},
    { itemName: 'Tin foil', itemType: 'Hats', contract: 'ItemTinFoil', tokenAddress: '0xf3126a961abc4cef0cefcb2510be431ce2782b7f', charity: 'ASPCA and ETH Foundation', assetUrl: 'tinfoil'},
    { itemName: 'Top Hat', itemType: 'Hats', contract: 'ItemTopHat', tokenAddress: '0xcd1b3ecffeacdcfc01054848225eb627bc9c590f', assetUrl: 'tophat'},
    { itemName: 'Trapper', itemType: 'Hats', contract: 'ItemTrapper', tokenAddress: '0x8b3c87830bb23da1270803f92ff8ffa9d4a67338', charity: 'Pussyhat Project', assetUrl: 'trapper'},
    { itemName: 'Viking Helmet', itemType: 'Hats', contract: 'ItemVikingHat', tokenAddress: '0xb106e0cfd724fca820d342b5a4ac2526108a6a56', artistName: 'KittyHawk', assetUrl: 'vikinghelmet'},
    { itemName: 'Blue Top Hat', itemType: 'Hats', contract: 'ItemTopHatBlue', tokenAddress: '0xf62215163b2dbbc0b8a57f7ce8238b4d7a3b8aea', artistName: 'KittyHawk', assetUrl: 'tophat_blue'},
    { itemName: 'Blue Beret', itemType: 'Hats', contract: 'ItemBeret', tokenAddress: '0x075dd07ff62488810d30d61c93dc4be94eb31be9', artistName: 'KittyHawk', assetUrl: 'beret_blue'},
    { itemName: 'Shower Cap', itemType: 'Hats', contract: 'ItemShowerCap', tokenAddress: '0xb7e485240fb515cb6d38ef06824b1fa1db05ff8b', artistName: 'Laura O', assetUrl: 'shower_cap'},
    { itemName: 'Cheesehead Hat', itemType: 'Hats', contract: 'ItemCheeseheadHat', tokenAddress: '0x17185d3b4d1e2630366886f78cb11d6ec807df40', artistName: 'ParanoidLunatic', assetUrl: 'cheesehat'},
    { itemName: 'Construction Hat', itemType: 'Hats', contract: 'ItemHardHat', tokenAddress: '0x0cc0bb67b6b0c92c56328271cd6d767caa8494ab', artistName: 'ParanoidLunatic', assetUrl: 'construction'},
    { itemName: 'Graduation Cap', itemType: 'Hats', contract: 'ItemGraduationCap', tokenAddress: '0x54cd89c959178a4303544fc326b3fc4e7a5a17ed', artistName: 'ParanoidLunatic', assetUrl: 'graduation'},
    { itemName: "Tie'd Pod", itemType: 'Memes', contract: 'ItemMemeTidePod', tokenAddress: '0x9cbd70665cfc178dd5598bd8b9e3cccebe34f948', artistName: 'ADHDKitties', assetUrl: 'tidepodbowtie'},
    { itemName: 'Cucumbers Behind Cats', itemType: 'Memes', contract: 'ItemMemeCucumber', tokenAddress: '0x5c7c99a3ce83c3400ea21608651e7941a7ff1b92', artistName: 'BxKat', assetUrl: 'cucumber'},
    { itemName: 'Chemistry Cat', itemType: 'Memes', contract: 'ItemMemeScience', tokenAddress: '0xcc45a3f00a5a672b3f2f3efcf9a8325db9448bc0', artistName: 'BxKat', assetUrl: 'chemistrycat'},
    { itemName: 'Breading', itemType: 'Memes', contract: 'ItemMemeBread', tokenAddress: '0x2881d326f657c31fc538caa72c8c8ea5dc9da9a9', artistName: 'BxKat', assetUrl: 'breading'},
    { itemName: 'Keyboard Cat', itemType: 'Memes', contract: 'ItemMemeKeyboard', tokenAddress: '0x98f1ada35615e768aa30e217f62d52fa723bcda6', artistName: 'BxKat', assetUrl: 'keyboard'},
    { itemName: "Maru's Tiny Box", itemType: 'Memes', contract: 'ItemMemeMaruBox', tokenAddress: '0xae49153ec8d6aed68d0d165a946684d9e27fd9b1', artistName: 'BxKat', assetUrl: 'tinybox'},
    { itemName: 'More Treats?! Strawberry Hat', itemType: 'Memes', contract: 'ItemMemeStrawberryHat', tokenAddress: '0x8d0d00744df3c57d434f82b15cb1a643ba46cd67', artistName: 'BxKat', assetUrl: 'strawberryhat'},
    { itemName: 'More Treats?! Pumpkin Hat', itemType: 'Memes', contract: 'ItemMemePumpkinHat', tokenAddress: '0xde4e1963fbd541bf51e6eeb8a95326623aca17a8', artistName: 'BxKat', assetUrl: 'pumpkinhat'},
    { itemName: 'Deal With It Shades', itemType: 'Memes', contract: 'ItemMemeDealWithItShades', tokenAddress: '0x7a72acc9b57c7c58189dd3370edbfb03621bd9a1', artistName: 'ParanoidLunatic', assetUrl: 'dealwithit'},
    { itemName: 'Candy Hearts', itemType: 'Seasonal', contract: 'ItemValentinesCandyHearts', tokenAddress: '0xe09a0238153a0c225e1157974990b8b9559cd2b6', artistName: 'BxKat', assetUrl: 'candyhearts'},
    { itemName: 'Surrounded by Love', itemType: 'Seasonal', contract: 'ItemValentinesHeartsBackground', tokenAddress: '0x104c09b7696744ad6f7a68af0d6cec10238a94a4', artistName: 'BxKat', assetUrl: 'heartsbg'},
    { itemName: 'Birds of Prey Helmet', itemType: 'Seasonal', contract: 'ItemHelmetBird', tokenAddress: '0x8f5155ddc1a859c22ba3d7a2f409175e69933cdc', artistName: 'KittyHawk', assetUrl: 'helmet_football_birdsofprey'},
    { itemName: 'Patriotic Helmet', itemType: 'Seasonal', contract: 'ItemHelmetPat', tokenAddress: '0x0dd3b0163c8974e1673601c06ab2a45d762aaff8', artistName: 'KittyHawk', assetUrl: 'helmet_football_patriotic'},
    { itemName: 'Hearts Headband', itemType: 'Seasonal', contract: 'ItemValentinesHeartsHeadband', tokenAddress: '0x88a2213dc4340b1333afa386d4db7a3b7a425230', artistName: 'KittyHawk', assetUrl: 'headband_heart'},
    { itemName: 'Chucks', itemType: 'Shoes', contract: 'ItemChucks', tokenAddress: '0xd822ca23fcdbf5569b3ce746a81f039b27240b35', assetUrl: 'chucks'},
    { itemName: 'Mittens', itemType: 'Shoes', contract: 'ItemMittens', tokenAddress: '0x944bf9dfee296df739a68b07e53c97df0396dbb3', assetUrl: 'mittens'},
    { itemName: 'Ballet Slippers', itemType: 'Shoes', contract: 'ItemBlackBalletSlippers', tokenAddress: '0xac740ff5f0ed950f63c86ee32ad87c0e54b50488', artistName: 'KittyHawk', assetUrl: 'balletslippers'},
    { itemName: 'Yeezys', itemType: 'Shoes', contract: 'ItemYeezy', tokenAddress: '0x6615f387e7bc610c11066cb1e534ab0f07ed8424', assetUrl: 'yeezy'},
    { itemName: '411 Gold Medal Award', itemType: 'Special', contract: 'ItemJodiMedal', tokenAddress: '0xc722587e4bc7b6b7555514b2d6dd3326f3b3ec16', artistName: 'KittyHawk', assetUrl: 'gold_medal_411'},
    { itemName: 'Kitties for a Cause Exclusive', itemType: 'Special', contract: 'ItemBellaBalloon', tokenAddress: '0x1fed670f81162db861dd41b8125ec2ded36f26b3', artistName: 'KittyHawk', assetUrl: 'k4c_balloon'},
    { itemName: 'Pizza Turntable', itemType: 'Special', contract: 'ItemPizza', tokenAddress: '0xbd5063809907ed5b56ba1889704559b99c669c86', artistName: 'KittyHawk', assetUrl: 'pizza_turntable'},
    { itemName: 'Argentina Hat', contract: 'ItemArgentinaHat', tokenAddress: '0xff273252c6c18513fa5f15c7c242bd1173a2f2c6', artistName: 'B. Yaporandy', assetUrl: 'argentinahat'},
    { itemName: 'Brazil Hat', itemType: 'World Cup (2018)', contract: 'ItemBrazilHat', tokenAddress: '0x5b01805d060859aa66ec2e0e753022dd6ce0f006', artistName: 'B. Yaporandy', assetUrl: 'brazilhat'},
    { itemName: 'England Hat', itemType: 'World Cup (2018)', contract: 'ItemEnglandHat', tokenAddress: '0xb6852dc531b77a18e7933e6b92f674ad94c37aff', artistName: 'B. Yaporandy', assetUrl: 'englandhat'},
    { itemName: 'France Hat', itemType: 'World Cup (2018)', contract: 'ItemFranceHat', tokenAddress: '0x736d553f1885241aa186f00f7525116195af4d9c', artistName: 'B. Yaporandy', assetUrl: 'francehat'},
    { itemName: 'Belgium Hat', itemType: 'World Cup (2018)', contract: 'ItemGermanyHat', tokenAddress: '0x71d439e6d2c68b1b1eb850b3f05c133582eaedc8', artistName: 'B. Yaporandy', assetUrl: 'germanyhat'},
    { itemName: 'Spain Hat', itemType: 'World Cup (2018)', contract: 'ItemSpainHat', tokenAddress: '0x6cafeecfbcf707406ba3d2f95623a8f9b4ce71d1', artistName: 'B. Yaporandy', assetUrl: 'spainhat'},
    { itemName: 'Soccer Ball Hat', itemType: 'World Cup (2018)', contract: 'ItemSoccerBallHat', tokenAddress: '0x27821e28c934422f83a3413256333c8d3fff1890', artistName: 'B. Yaporandy', assetUrl: 'soccerballhat'},
    { itemName: 'Brazil Uniform', itemType: 'World Cup (2018)', contract: 'ItemBrazilianUniform', tokenAddress: '0xab90e69101b75f16b421b07100aa6859ca125add', artistName: 'B. Yaporandy', assetUrl: 'braziluniform'},
    { itemName: 'Russian Mascot', itemType: 'World Cup (2018)', contract: 'ItemRussianMascot', tokenAddress: '0x2b29309b67afc9df29c69b1ce86656aaf12941c0', artistName: 'B. Yaporandy', assetUrl: 'russianmascot'},
    { itemName: 'World Cup Glasses', itemType: 'World Cup (2018)', contract: 'ItemWorldCupGlasses', tokenAddress: '0x57e63a51b9010eb01a994e3f12be6f1217c44e3c', artistName: 'B. Yaporandy', assetUrl: 'worldcupglasses'},
    { itemName: 'Kitty World Cup Trophy', itemType: 'World Cup (2018)', contract: 'ItemWorldCupTrophy', tokenAddress: '0xdf78966498cdb99158e3c706adaf9bf9f58f80ed', artistName: 'B. Yaporandy', assetUrl: 'worldcuptrophy'},
    { itemName: 'Confetti', itemType: 'World Cup (2018)', contract: 'ItemConfetti', tokenAddress: '0x3f50e90be93e5581bc76874eaded1092ea42bb58', artistName: 'BxKat', assetUrl: 'confetti'},
    { itemName: 'Jester Viking Hat', itemType: 'World Cup (2018)', contract: 'ItemJesterVikingHat', tokenAddress: '0x25d548d9052b1c3cf3f82004a1dcb8c13c2f147f', artistName: 'BxKat', assetUrl: 'soccerhatgreenblueyellow'},
    { itemName: 'Jester Hat', itemType: 'World Cup (2018)', contract: 'ItemJesterHat', tokenAddress: '0xcf3ded632f25bdd42f0d9e52be8eb10dfa4a745c', artistName: 'BxKat', assetUrl: 'soccerhatredwhiteblue'},
    { itemName: 'Soccer Ball', itemType: 'World Cup (2018)', contract: 'ItemSoccerBall', tokenAddress: '0x4f1079361f3950de291d13290f0d74049d695992', artistName: 'BxKat', assetUrl: 'soccerball'},
    { itemName: 'Oversized Glasses', itemType: 'World Cup (2018)', contract: 'ItemOversizeSoccerGlasses', tokenAddress: '0x467538c02628827ee35e928f56e1e9acd6651537', artistName: 'BxKat', assetUrl: 'oversizesoccerglasses'},
    { itemName: 'Glasses with Vuvuzela', itemType: 'World Cup (2018)', contract: 'ItemVuvuGlasses', tokenAddress: '0x478919071c22833ab31d685511e4b51616935f7a', artistName: 'BxKat', assetUrl: 'vuvuwithglasses'},
    { itemName: 'Too many Vuvuzelas!', itemType: 'World Cup (2018)', contract: 'ItemTooManyVuvus', tokenAddress: '0x665c2854084b6877d7e947e6c2b18ba762629c10', artistName: 'BxKat', assetUrl: 'toomanyvuvus'}
]