import React from "react";
import * as Styled from "./Jewels.style"

const Jewels = ({ kitty, displayType }) => {
  return (
    <Styled.Div>
    {
      kitty.enhanced_cattributes.map((c, i) => {
        if (displayType === "mewtations") {
          if (c.position === 1  && c.kittyId === kitty.id) return <Styled.Jewel key={i} type={'diamond'} trait={c.description} larger />
          if (c.position >= 2 && c.position <= 10  && c.kittyId === kitty.id) return <Styled.Jewel key={i} type={'gilded'} trait={c.description} larger />
          if (c.position >= 11 && c.position <= 100  && c.kittyId === kitty.id) return <Styled.Jewel key={i} type={'amethyst'} trait={c.description} larger />
          if (c.position >= 101 && c.position <= 500 && c.kittyId === kitty.id) return <Styled.Jewel key={i} type={'lapis'} trait={c.description} larger />
        } else { // displayType === "family-jewels"
          if (c.position === 1  && c.kittyId !== kitty.id) return <Styled.Jewel key={i} type={'diamond'} trait={c.description} />
          if (c.position >= 2 && c.position <= 10  && c.kittyId !== kitty.id) return <Styled.Jewel key={i} type={'gilded'} trait={c.description} />
          if (c.position >= 11 && c.position <= 100  && c.kittyId !== kitty.id) return <Styled.Jewel key={i} type={'amethyst'} trait={c.description} />
          if (c.position >= 101 && c.position <= 500 && c.kittyId !== kitty.id) return <Styled.Jewel key={i} type={'lapis'} trait={c.description} />
        }
      })
    }
  </Styled.Div>
  )
}

export default Jewels
