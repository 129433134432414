import React, { useState, useEffect } from 'react'
import axios from 'axios'
import loading from 'svg/loading.svg'
import * as Styled from './EthPrice.style'

const EthPrice = () => {
  const [ethPrice, setEthPrice] = useState(false)
  useEffect(() => {
    if (!ethPrice) {
      axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,GBP,EUR').then(res =>
        setEthPrice(res.data)
      ).catch(err => error(err))
    }
  })
  return (
    <Styled.Div>
      {'Ξ1 '}{(!ethPrice)
        ? <img src={loading} alt={''} />
        : <span>/ ${ethPrice.USD} / £{ethPrice.GBP} / €{ethPrice.EUR}</span>
      }
    </Styled.Div>
  )
}

export default EthPrice
