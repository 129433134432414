import styled from 'styled-components'
import { fontSize, gutters } from 'style/config'

export const Header = styled.header`
  width: 100%;
  max-width: 1100px;
  padding-top: 40px;
  margin: 0 auto;
  img {
    width:50px;
  }
  > h1 {
    margin-bottom: ${gutters['md']};
    font-size: 48px;
  }
  > p {
    font-family: "funkydori",sans-serif;
    font-size: 42px;
    color: #333;
    margin-bottom: ${gutters['lg']};
  }
`
