import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Portal = ({ container, children }) => {
	const containerRef = useRef(null)

	useEffect(() => {
		containerRef.current = document.querySelector(container)
	}, [container])

	return ReactDOM.createPortal(children, document.body)
}

Portal.propTypes = {
	container: PropTypes.string,
	children: PropTypes.node.isRequired
}

Portal.defaultProps = {
	container: 'body'
}

export default Portal
